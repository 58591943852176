// import 'babel-polyfill';
// import 'isomorphic-fetch';
import React from 'react'
import { StaticQuery, Link, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { FaArrowRight, FaPassport, FaUserAstronaut } from 'react-icons/fa'
import { BsLifePreserver } from 'react-icons/bs'
import { IoMdImages, IoMdShareAlt } from 'react-icons/io'
import { IoBagHandleOutline } from 'react-icons/io5'

import { MdPeople, MdGroups, MdEditCalendar, MdPublic } from 'react-icons/md'
import { AiOutlineVideoCamera, AiOutlineWallet } from 'react-icons/ai'
import {
  RiCoinLine,
  RiImageEditFill,
  RiHandHeartLine,
  RiDashboard3Line,
} from 'react-icons/ri'

const Sections = (props) => {
  console.log('Sections - props')
  console.log(props)

  const allSections =
    props && props.data && props.data.sections && props.data.sections.edges
      ? props.data.sections.edges
      : // .sort((a, b) =>
        //     a.node.data.sort > b.node.data.sort ? 1 : -1
        //   )
        []
  console.log('allSections')
  console.log(allSections)
  const allArticles =
    props && props.data && props.data.articles && props.data.articles.edges
      ? props.data.articles.edges
      : // .sort((a, b) =>
        //     a.node.data.sort > b.node.data.sort ? 1 : -1
        //   )
        []
  console.log('allArticles')
  console.log(allArticles)
  return (
    <>
      <div class="row d-flex justify-content-center">
        {allSections
          ? allSections.map((edge, i) => {
              let gradientColor
              switch (edge.node.data.color) {
                case 'gray':
                  gradientColor = 'slate'
                  break
                case 'dkorange':
                  gradientColor = 'fire'
                  break
                case 'purple':
                  gradientColor = 'tropical'
                  break
                case 'pink':
                  gradientColor = 'sunset'
                  break
                case 'dkblue':
                  gradientColor = 'cousteau'
                  break
                case 'blue':
                  gradientColor = 'bluesky'
                  break
                case 'yellow':
                  gradientColor = 'warm'
                  break
                case 'bluegreen':
                  gradientColor = 'deepsea'
                  break
                case 'dkgreen':
                  gradientColor = 'keywest'
                  break
                case 'seafoam':
                  gradientColor = 'seatosky'
                  break
                default:
                  gradientColor = 'cumulus'
              }
              return (
                <div class="col-md-4 mt-0 mb-1 d-flex flex-column">
                  <div
                    class={`round-corners-top mt-2 gradient-callout-${gradientColor} white-1 p-2`}
                  >
                    <Link
                      class={`white-1`}
                      to={`/section/${edge.node.data.url}`}
                    >
                      {edge.node.data.title}
                    </Link>
                  </div>
                  <div
                    class={`d-flex flex-grow-1 container container-article-list round-corners-bottom border-bottom-1-${
                      edge.node.data.color ? edge.node.data.color : 'blue'
                    }-1 border-left-1-${
                      edge.node.data.color ? edge.node.data.color : 'blue'
                    }-1 border-right-1-${
                      edge.node.data.color ? edge.node.data.color : 'blue'
                    }-1 p-3 mb-4`}
                  >
                    <ul class="home-section-link-body d-flex flex-column">
                      {allArticles
                        ? allArticles
                            .filter(
                              (edgeArticle) =>
                                edgeArticle.node.data.categorystring ===
                                edge.node.data.catid
                            )
                            .map((edgeArticle, j) => (
                              <li class="line-height-3 py-1">
                                <Link
                                  class="gray-1 "
                                  to={`/article/${edgeArticle.node.data.url}`}
                                >
                                  {edgeArticle.node.data.title}
                                </Link>
                              </li>
                            ))
                        : null}
                      <li class="mt-auto py-1 pt-3">
                        <Link
                          class="gray-1 font-weight-medium"
                          to={`/section/${edge.node.data.url}`}
                        >
                          All {edge.node.data.title} articles{' '}
                          <FaArrowRight class="btn-arrow" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              )
            })
          : null}
      </div>
    </>
  )
}
const Snippet = (props) => (
  <div class="row-platform-link d-flex flex-row align-items-center justify-content-center flex-wrap my-4">
    {Sections(props)}
  </div>
)

export default Snippet
