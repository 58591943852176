//React
import React from 'react'

//Gatsby
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

//Packages
import Helmet from 'react-helmet'
// import { AnchorLink } from 'gatsby-plugin-anchor-links'

//Components
import Layout from '../components/layout'
import SupportBody from '../components/marketing/supportBody'

//Icons
import { FaArrowRight } from 'react-icons/fa'

const Snippet = (props) => {
  const { data } = props
  console.log('pageData')
  console.log(data)
  const sections = data?.sections
  const articles = data?.articles
  return (
    <Layout>
      <Helmet
        title="PicoNext - Support"
        meta={[
          {
            name: 'description',
            content: 'Get the guidance you need to successfully use PicoNext',
          },
        ]}
      />

      <div class="gradient-callout-tropical">
        <div class="container container-page container-about-sub">
          <div class="row">
            <div class="col-lg-10 d-flex flex-column justify-content-center text-center text-lg-left">
              <h1 class="display-2 font-weight-medium white-1">Support</h1>
              <p class="display-4 white-4">
                Get the guidance you need to successfully use PicoNext
              </p>
            </div>
          </div>
        </div>
      </div>

      <SupportBody sections={sections} articles={articles} />
    </Layout>
  )
}
export default Snippet

export const pageQuery = graphql`
  {
    sections: allAirtable(
      filter: { table: { eq: "category" }, data: { publish: { eq: 1 } } }
      sort: { fields: data___sort, order: ASC }
    ) {
      edges {
        node {
          id
          data {
            catid
            publish
            sort
            title
            url
            color
          }
        }
      }
    }
    articles: allAirtable(
      filter: {
        table: { eq: "article" }
        data: { publish: { eq: 1 }, featured: { eq: 1 } }
      }
      sort: { fields: data___sort, order: ASC }
    ) {
      edges {
        node {
          id
          data {
            categorystring
            publish
            sort
            title
            featured
            url
          }
        }
      }
    }
  }
`
