// import 'babel-polyfill';
// import 'isomorphic-fetch';
import React from 'react'

//Gatsby
import { StaticQuery, Link, graphql } from 'gatsby'
import Img from 'gatsby-image'

//Components
import SupportSections from './supportSections'

//Icons
import { FaArrowRight, FaPassport } from 'react-icons/fa'
import { BsLifePreserver } from 'react-icons/bs'
import { IoMdImages, IoMdShareAlt } from 'react-icons/io'
import { MdPeople } from 'react-icons/md'
import { AiOutlineVideoCamera } from 'react-icons/ai'
import { RiCoinLine } from 'react-icons/ri'

const Snippet = (props) => (
  <StaticQuery
    query={graphql`
      query {
        featureSupport: file(
          relativePath: {
            eq: "stock/unsplash/christina-wocintechchat-com-J7EDyqUwXJI-unsplash.jpg"
          }
        ) {
          childImageSharp {
            fixed(width: 260, height: 200, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={(data) => (
      <section class="pricing mb-4">
        <div class="container">
          <div class="row d-flex align-items-center justify-content-center">
            <div class="col-lg-12">
              <div class="border-1-dkblue-1 rounded-corners mt-4 p-2 small">
                <strong>New:</strong> Get started quickly with a tutorial on{' '}
                <Link class="underline" to="/article/tutorial-dpp-cloud">
                  Creating a Cloud-based DPP
                </Link>
              </div>
            </div>
          </div>
          <div class="row d-flex align-items-stretch justify-content-center">
            <div class="col-lg-12">
              {/* <p class="lead text-center font-weight-medium">
                Explore articles on how to get started with PicoNext
              </p> */}

              <SupportSections data={props} />
              {/* <div class="card mb-5 mb-lg-0">
                <div class="card-title gradient-callout-overcast round-corners-top py-2">
                  <h5 class="card-title text-center white-1 pt-2">
                    Getting started
                  </h5>
                </div>
                <div class="card-body d-flex flex-column">
                  <p class="text-center lead font-weight-light line-height-3">
                    Read articles on how to get started with PicoNext
                  </p>
                  <SupportSections data={props} />
                  <Link
                    to="/articles"
                    class="btn btn-primary btn-cta mt-auto w-50 align-self-center"
                  >
                    See all articles <FaArrowRight class="btn-arrow" />
                  </Link>
                </div>
              </div> */}
            </div>
            <hr size={1} />
            <div class="col-lg-12 mt-4 mb-3">
              <div class="card mb-5 mb-lg-0">
                <div class="card-title gradient-callout-overcast round-corners-top py-2">
                  <h5 class="card-title text-center white-1 pt-2">Resources</h5>
                </div>
                <div class="card-body d-flex flex-column align-items-center justify-content-start">
                  <div class="resource-item d-flex flex-column align-items-center justify-content-start mb-3">
                    <p class="text-center lead font-weight-light line-height-3">
                      Check the current status of PicoNext services
                    </p>

                    <a
                      href="https://status.piconft.net/"
                      class="btn btn-primary btn-cta"
                    >
                      See status <FaArrowRight class="btn-arrow" />
                    </a>
                  </div>
                  <hr size={1} />
                  <div class="resource-item d-flex flex-column align-items-center justify-content-start mb-3">
                    <p class="text-center lead font-weight-light line-height-3">
                      Launch your project quickly with professional services
                      from PicoNext
                    </p>
                    {/* <p class="text-center font-weight-light line-height-3">
                      Use consulting, creative services, and more to get your
                      program up-and-running fast
                    </p> */}
                    {/* <Img
                    className="round-corners mb-2"
                    fixed={data.featureSupport.childImageSharp.fixed}
                  /> */}

                    <a
                      href={`${process.env.MARKETING_DOMAIN}request-pro-services`}
                      class="btn btn-primary btn-cta"
                    >
                      Contact Pro Services <FaArrowRight class="btn-arrow" />
                    </a>
                  </div>
                  {/* <hr size={1} /> */}
                  {/* <div class="resource-item d-flex flex-column align-items-center justify-content-start mb-3">
                    <p class="text-center lead font-weight-light line-height-3">
                      Access help and guidance on how to use PicoNext
                    </p>

                    <a
                      href="mailto:support@piconext.com"
                      class="btn btn-primary btn-cta"
                    >
                      Contact support <FaArrowRight class="btn-arrow" />
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
            {/* <div class="col-lg-4">
          <div class="card mt-3 mt-lg-0 mb-5 mb-lg-0">
            <div class="card-body d-flex flex-column">
              <h5 class="card-title text-center  sky-1">Organizations</h5>
              <hr />
              <p class="text-center font-weight-light">
                Use onboarding resources and customized support to make your
                investment a success
              </p>
              <Link to="/request-demo/" class="btn btn-primary btn-cta mt-auto">
                Request demo <FaArrowRight class="btn-arrow" />
              </Link>
            </div>
          </div>
        </div> */}
          </div>
        </div>
      </section>
    )}
  />
)

export default Snippet
